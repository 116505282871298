import React, { useState, useContext, useEffect } from 'react'

import QRCodeStyling from "qr-code-styling";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider';
import { Path } from "../../paths";
import SearchIcon from '@mui/icons-material/Search';
import {
  businessesApi,
  useEditBusinessMutation,
  useGetAllBusinessesQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase, InputAdornment, Pagination } from '@mui/material';
import QRViewComponent from '../../components/QRViewComponent';

const SearchBusinessQR: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetAllBusinessesQuery();


  const form = useForm<Business>({

  })

  const [filteredBusinesses, setFilteredBusinesses] = useState(data);
  const [searchValue, setSearchValue] = useState('');

  const [error, setError] = useState('')



  const authContext = useContext(AuthContext)

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
    }

    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page"))!);
    }
  }, []);
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (array: Business[], pageSize: number) => {
    const pageCount = Math.ceil(array.length / pageSize);
    return Array.from({ length: pageCount }, (_, index) =>
      array.slice(index * pageSize, (index + 1) * pageSize)
    );
  };

  const pageSize = 5;

  const [shrink, setShrink] = useState(false);

  
  useEffect(() => {
  
   
    const filtered = data?.filter((business) => business.businessName != "");
    
    setCurrentPage(1)
    setFilteredBusinesses(filtered)
    
  
}, [data]);


  if (isLoading) {
    return <span>Loading</span>;
  }



  const paginatedPosts = paginate(filteredBusinesses || [], pageSize);
  const currentPosts = paginatedPosts[currentPage - 1];


  //console.log(authContext.sessionInfo);
  //console.log("Search = " + authContext.sessionInfo?.profileComplete)
  if (!authContext.profileComplete && authContext.sessionInfo?.usertype === "business") {
    navigate(`${Path.businessEdit}/`);

  } else if (!authContext.profileComplete && authContext.sessionInfo?.usertype === "employee") {

    navigate(`${Path.employeeEdit}/`);
  }
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }


  const handleInputChange = (e: any) => {
    const filtered = data?.filter((business) => business.businessName.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredBusinesses(filtered);
  }
  const editBusiness = () => {

    navigate(`${Path.businessEdit}/${authContext.sessionInfo?.sub}`);
  }
  const confirmBusiness = () => {

    navigate(`${Path.businessInvite}/${authContext.sessionInfo?.sub}`);
  }
  const finalize = (
    <>




      {/* Buttons */}


      {currentPosts && currentPosts?.map((business: Business) =>
        <>
          <Box mt={2} width="100%">

            <Link to={'/business/view/' + business?.id}>
              <Paper elevation={0} variant="outlined"
                sx={{
                  p: 1,
                  width: "100%",
                  flexGrow: 1,
                  borderRadius: "25px",
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid container direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap">
                  <Grid item >
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "90px", width: "90px" }} src={business?.logoUrl} />
                    </ButtonBase>
                  </Grid>
                  <Grid item container alignItems="center" sx={{ width: "70%", display: "flex" }}>
                    <Grid item container direction="column" alignItems="left" width="100%" alignContent="left" zeroMinWidth>
                      <Grid item zeroMinWidth sx={{ width: "100%", display: "flex" }}>
                        <Typography noWrap align="left" sx={{ overflowWrap: 'break-word' }} variant="h3" component="div">
                          {business?.businessName}
                        </Typography>
                      </Grid>
                      <Grid item zeroMinWidth sx={{ width: "100%", display: "flex" }}>

                        <Typography noWrap variant="subtitle1" sx={{ overflowWrap: 'break-word' }} >
                          {business?.address}
                        </Typography>
                      </Grid>
                      <Grid item zeroMinWidth sx={{ width: "100%", display: "flex" }}>
                        <Typography noWrap variant="subtitle1" sx={{ textDecoration: "none", overflowWrap: 'break-word' }} color="text.secondary">
                          <Link style={{ color: "#000000" }} target="_blank" to={business ? business.website.includes("http") ? business.website : "https://" + business.website : ""} >{business?.website}</Link>
                        </Typography>
                      </Grid>
                      <Grid item zeroMinWidth sx={{ width: "100%", display: "flex" }}>
                        <Typography noWrap variant="subtitle1" sx={{ textDecoration: "none", overflowWrap: 'break-word' }} color="text.secondary">
                          <Link style={{ color: "#000000" }} to={business ? "tel:" + business?.phone : ""} >{business?.phone}</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Box>
                        <QRViewComponent urlParam={business.id} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Link>



          </Box>
        </>
      )
      }

      {paginatedPosts && paginatedPosts.length > 1 && (
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            size="large"
            siblingCount={1}
            boundaryCount={1}
            count={paginatedPosts.length}
            page={currentPage}
            onChange={(_, newPage) => setCurrentPage(newPage)}
          />
        </Box>
      )}
    </>

  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          <Box width="100%" mt={1} mb={1}>
            <TextField
              onChange={handleInputChange}
              variant='filled'

              onFocus={() => setShrink(true)}
              onBlur={(e) => setShrink(!!e.target.value)}
              InputLabelProps={{
                shrink,
                sx: { left: "35px" }
              }}
              sx={(theme) => ({
                '& .MuiInputAdornment-root': {
                  p: 0,
                  mb: 2,
                  ml: 0
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  px: 25.5,
                },
              })}
              InputProps={{



                sx: { borderRadius: 25, borderStyle: "none", elevation: 0, borderBottom: 0 },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              type="text"
              name="Search"
              id="search"
              label={'Search by business'} />

          </Box>
          <Box width="100%" mt={1} mb={1}>
            <Divider orientation="horizontal" flexItem />
          </Box>
          {finalize}
        </Grid>
      </Grid>
    </Grid>

  )
}

export default SearchBusinessQR
