import React, { useState, useContext, useRef, useEffect } from 'react'
import { Image } from 'image-js';
import { Buffer } from 'buffer';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Path } from "../../paths";
import Paper from '@mui/material/Paper'

import {
  useAddBusinessMutation,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";
import AWS from 'aws-sdk';

import useFilePreview from "../../hooks/useFilePreview";
import TextField from '@mui/material/TextField'


import { AuthContext } from '../../context/authContext'
import { Controller, useForm } from "react-hook-form";


import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Avatar, Input } from '@mui/material';
import ImageCrop from 'mui-image-crop';
import { ICroppedImage } from 'mui-image-crop/es/types';
import { useValidEmail } from '../../hooks/useAuthHooks';



function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const s3Config = {
  bucketName: 'tipzbucket',
  dirName: '',
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY || "",
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY || "",
  s3Url: ''
};


const SetupBusiness: React.FunctionComponent<{}> = () => {
  const [croppedImage, setCroppedImage] = useState<ICroppedImage>();
  

  


  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  const authContext = useContext(AuthContext)

  const params = useParams<{ id: string }>();
  
  const [addBusiness] = useAddBusinessMutation();
  //console.log(authContext.sessionInfo?.sub)
  //console.log("Dat=" + data?.id);
  const form = useForm<Business>({

  })

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    setOpen(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const [sent, setSent] = useState(false);
  const { register, control,handleSubmit, watch, reset,
    formState,
    formState: { isSubmitSuccessful }
  } = form;
  
  const logoUrlRef = useRef<HTMLInputElement | null>(null);

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const isValid =
  !emailIsValid ||
  email.length === 0
  
  useEffect(() => {
    if (isValid){
      setSent(false);
    }
  });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setSent(true);
      reset()
    }
  }, [formState, reset]);
  const [error, setError] = useState('')
  const [setup, setSetup] = useState(false)
  

  if (searchParams.get("setup") === "true" && !setup) {
    setSetup(true)
  }


  
  const addNewBusiness = async (data: Business) => {
    try {

      console.log("Adding Business " + data.id)
      await addBusiness(data).unwrap();
      
      //navigate(`/business/edit`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }


  const handleEditBusiness = async (business: Business) => {

    const res: any = await authContext.signUpWithAdminEmail(business.email, business.email, "TipzPassworD012487")
    console.log(res);
    try {



      let v = 0;
     
      if (croppedImage) {

        //   //console.log("https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo.sub + "" + fileExt)
        business.logoUrl = "https://dpcy146y2wbkr.cloudfront.net/" + res + ".v" + v + ".png";

      } else {
       
          business.logoUrl = "";
        
        // "https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo?.sub + "" + fileExt
      }
      // //console.log("https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo.sub + "" + fileExt)
      business.id = res;
      business.password = "";
      business.joinDate = new Date()
      business.stripeid=""
      business.stripeverified=0
//console.log("Address = "+business.address);
      
      await addNewBusiness(business);

    //  if (data?.businessName === "")
    //    sendEmailBusinessComplete(business.businessName || "", data?.email || "")

      //console.log(croppedImage?.url)
      //await authContext.setProfileComplete(business.businessName)
      if (croppedImage) {
        await uploadImage(res + ".v" + v + ".png")


      }


    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };



  const uploadImage = async (filename: string) => {
    if (!croppedImage)
      return;


    let img = document.createElement('img');
    img.src = croppedImage.url
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    if (!ctx)
      return;
    ctx.drawImage(img, 0, 0);

    let width = img.width;
    let height = img.height;
    let max_dimension = 1024;
    if (width > height) {
      if (width > max_dimension) {
        height *= max_dimension / width;
        width = max_dimension;
      }
    }
    else {
      if (height > max_dimension) {
        width *= max_dimension / height;
        height = max_dimension;
      }
    }
    canvas.width = width;
    canvas.height = height;

    ctx = canvas.getContext("2d");
    if (!ctx)
      return;
    ctx.drawImage(img, 0, 0, width, height);
    let extension = ".png";
    let type = "image/" + extension;
    let data_url = canvas.toDataURL(type);

    const file = dataURLtoFile(data_url, filename);

    AWS.config.update({
      accessKeyId: s3Config.accessKeyId,
      secretAccessKey: s3Config.secretAccessKey,
    });

    const s3 = new AWS.S3({
      params: { Bucket: s3Config.bucketName },
      region: s3Config.region,

    });

    const params = {
      Bucket: s3Config.bucketName,
      Key: filename,
      Body: file,
      ContentType: 'image/png',
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        //console.log("Uploading"
       // );
      })
      .promise();

    await upload.then((err) => {
      //console.log("Upload" + err);
      // alert("File uploaded successfully.");
    });

  }

  
  const handleClick = () => {
    logoUrlRef.current?.click()
  }
  //console.log("Cropped " + data?.logoUrl);
  const hello = (
    <>

      <Box justifyItems="center" width="100%" >


        <form onSubmit={handleSubmit(handleEditBusiness)}>

          <Grid container justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center">
            <Grid item justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center">

              <Box width="100%" justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center" >

                <ImageCrop

                  value={croppedImage}
                  onChange={setCroppedImage}
                  defaultAspect={1 / 1}
                  aspect={1 / 1}
                  showAspectToolbar={false}
                  preview={false}
                  uploaderProps={{
                    style: {
                      border: 'none',
                      width: 'auto',
                      height: 'auto',
                    },
                  }}
                >

                  {
                    (croppedImage?.url === "" || croppedImage === null || croppedImage === undefined)
                      ?
                      
                        <Box bgcolor={'#F9F9F9'} p={2} sx={{ borderRadius: 3 }} height={'150px'} justifyContent="center" alignItems={'center'}>


                          <Grid container justifyContent="center" height="100%" alignItems={'center'} direction="column">
                            <Grid item justifyContent="center" alignItems={'center'}>
                              <FileUploadOutlinedIcon sx={{ color: "#B3B1B1" }} /></Grid>
                            <Grid item justifyContent="center" alignItems={'center'}>
                              <Typography variant="h3" color={"#B3B1B1"} >Business Logo</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      :
                      <Avatar sx={{ height: "128px", width: "128px" }} src={croppedImage?.url} />

                  }


                </ImageCrop>
              </Box>
            </Grid>
          </Grid>

          <Box mt={1} mb={1} width="100%" justifyItems="center" justifyContent='center'>
            <TextField
              fullWidth
              
              inputProps={{
                maxLength: 100,
              }}
              type="text"
              {...register("businessName", { value: "" })}
              label={'Business Name'} />


          </Box>

          <Box mt={1} mb={1} width="100%" justifyItems="center" justifyContent='center'>

          <TextField
                  fullWidth
                  label="Email"
                  {...register("email")}
                  onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setEmail(evt.target.value)
                  }}
                />

           


          </Box>
          <Box width="100%" mt={1} mb={1}>
           
            <TextField
              fullWidth
              type="text"
              
              inputProps={{
                maxLength: 100,
              }}
              {...register("address", { value: "" })}
              variant="outlined"
              label={'Address'}
            />
          </Box>
         
          <Box width="100%" mt={1} mb={1}>
            <TextField
            
            inputProps={{
              maxLength: 100,
            }}
              fullWidth
              type="text"
              variant="outlined"
              {...register("website", { value: "" })}
              label={'Website (Optional)'}
            />
          </Box>
          <Box width="100%" mt={1} mb={1}>

            <Controller
              {...register('phone', { value: "" })}
              name="phone"
              control={control}
              render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => (
                <MuiTelInput
                inputProps={{ inputMode: 'numeric', maxLength:20 }}
                  defaultCountry="US"
                  fullWidth
                  label={'Phone (Optional)'}
                  variant="outlined"
                  {...fieldProps}
                  value={value ?? ''}
                  inputRef={fieldRef}
                />
              )}
            />


          </Box>
          <Box width="100%" mt={1} mb={1}>
            <TextField
              fullWidth
              type="text"
              
              inputProps={{
                maxLength: 100,
              }}
              variant="outlined"
              {...register("name", { value: "" })}
              label={'Your name (Optional)'}
            />
          </Box>

          <Box width="100%" mt={1} mb={1}>
            <TextField
              fullWidth
              type="text"
              
              inputProps={{
                maxLength: 300,
              }}
              variant="outlined"
              {...register("bio", { value: "" })}
              label={'Bio (Optional)'}
            />
          </Box>

          <Box width="100%" mt={1} mb={1}>
            <TextField
              fullWidth
              type="text"
              
              inputProps={{
                maxLength: 100,
              }}
              variant="outlined"
              {...register("social", { value: "" })}
              label={'Link to Social (Optional'}
            />
          </Box>
          <Box mt={2}>
            <Typography color="error" variant="body1">
              {error}
            </Typography>
          </Box>

          {/* Buttons */}
          <Box width="100%" justifyContent="center" alignContent="center">
            <Grid container direction="row" justifyContent="center" alignContent="center" >
              <Box width="100%" justifyContent="center" alignContent="center">
                <Button fullWidth color="primary" type="submit" variant="roundedBlack">
                  Add Business
                </Button>
              </Box>
              {sent ?
                  <Box width="100%" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                    <Typography align="center" variant="subtitle2">Business Added
                    </Typography>
                  </Box>
                  : <></>

                }
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" justifyItems="center" justifyContent='center' alignItems="center" item>
        <Grid container direction="column" justifyItems="center" justifyContent="center" alignItems="center">
          {/* Title */}
          <Box  mb={1}>
            <Grid container direction="column" alignItems="center">
              <Typography align="center" variant="h1">Say Hello</Typography>
              <Typography align="center" variant="subtitle1">Create your client facing profile.</Typography>
            </Grid>
          </Box>

          {hello}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SetupBusiness
