import React, { useState, useContext } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { useAddEmployeeMutation } from "../../redux/services/employees";
import { Employee } from "@prisma/client";

import {
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'
import { Email, Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'
import { signInWithRedirect } from 'aws-amplify/auth';
import { sendEmailEmployeeSignup } from '../../libs/email';
import { Avatar } from '@mui/material';

const SignUpEmployee: React.FunctionComponent<{}> = () => {
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)
  const [addEmployee] = useAddEmployeeMutation();

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')


  const params = useParams<{ id: string }>();

  
  const { data, isLoading } = useGetBusinessQuery(params.id || "");


  const addNewEmployee = async (data: Employee) => {
    try {
      await addEmployee(data).unwrap();
      //navigate(`/business/edit`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }
  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0


  const authContext = useContext(AuthContext)

  const signInClicked = async () => {

    try {
      await authContext.signOut();
      const res: any = await authContext.signUpWithEmployeeEmail(email, email, password)
      setCreated(true)
      try {
        let employee: Employee = {
          email: '',
          id: res,
          password: '',
          name: '',
          photoUrl: '',
          joinDate: new Date(),
          stripeid: '',
          stripeverified: 0,
          title: '',
          userId: params?.id || '',
          bio: '',
          social: ''
        }
        employee.email = email
        //console.log("Employee ID = " + employee.id)
        //console.log("Business ID = " + employee.userId)
        // setCurrentBusiness(business)
        // //console.log(currentBusiness?.email);
        await addNewEmployee(employee);
        sendEmailEmployeeSignup(email);
        
        localStorage.setItem('email', email)
        navigate('/verifyemployee')
      } catch (err) {
        const mayBeError = isErrorWithMessage(err);
        if (mayBeError) {
          setError(err.data.message);
        } else {
          setError("Unknown Error");
        }

      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }
  function handleSignInGoogleClick(customState: string) {
    
    localStorage.setItem('businessid', data?.id!)
    signInWithRedirect({
      provider: "Google",
      customState
    });
  }
  function handleSignInFacebookClick(customState: string) {
    localStorage.setItem('businessid', data?.id!)
    signInWithRedirect({
      provider: "Facebook",
      customState
    });
  }
  function handleSignInAppleClick(customState: string) {
    localStorage.setItem('businessid', data?.id!)
    signInWithRedirect({
      provider: "Apple",
      customState
    });
  }

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/signin')
  }
  const signUp = (
    <>
      <Box mt={1}  width="100%">
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box mt={1}  width="100%">
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box mt={1}>
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      </Box>

      <Box width="100%" mt={1} >
            <Divider orientation="horizontal" flexItem />
          </Box>

      <Box mt={1}  width="100%" pt={0} alignContent="center" justifyContent="center">
        <Grid container direction="row" alignContent="center" justifyContent="center">
          <Box mt={1} mb={1}width="100%" alignContent="center" justifyContent="center">
            <Button disabled={isValid} fullWidth color="primary" variant="squareBlack" onClick={signInClicked}>
              Sign Up
            </Button>
          </Box> <Box mt={1} mb={1}  width="100%">
            <Divider orientation="horizontal" flexItem />
          </Box>
          <Box mt={1} width="100%">

            <Button onClick={() => { handleSignInGoogleClick("employee") }} sx={{ textTransform: "none" }} startIcon={<GoogleIcon />} fullWidth variant="squareOutline">
              Sign Up with Google
            </Button>
          </Box>
          <Box mt={1} mb={1} width="100%">

            <Button onClick={() => { handleSignInFacebookClick("employee") }} sx={{ textTransform: "none" }} startIcon={<FacebookIcon />} fullWidth variant="squareOutline">
              Sign Up with Facebook
            </Button>
          </Box>
          
          
          <Box mt={1} mb={1} width="100%" justifyContent="center">

            <Typography variant='subtitle1'  align="center" justifyContent="center" onClick={signIn} gutterBottom>
              Already have an account? Log in
            </Typography>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const accountCreated = (
    <>

      <Typography align='center' variant="h5">{`Created ${email} account`}</Typography>
      <Typography align='center' variant="h6">{`Verify Code sent to ${email}`}</Typography>


      <Box mt={3} mb={3}>
        <Button onClick={() => navigate('/verify')} color="primary" variant="contained">
          Send Code
        </Button>
      </Box>

    </>
  )

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="center" item>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          {/* Title */}
          <Grid container textAlign="center" justifyContent="center" direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
            <Grid item textAlign="center" justifyContent="center">
        <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.logoUrl} />

        </Grid>
        </Grid>
          <Box mt={3} mb={3} justifyContent="center">
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="h1" gutterBottom>Employee Sign Up</Typography>
              <Typography variant="h4">A better way to tip is a few clicks away.</Typography>
            </Grid>
          </Box>

          {signUp}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUpEmployee
