import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import QrCodeIcon from '@mui/icons-material/QrCode';

import {
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import EmployeeList from '../../components/EmployeeList';
import { Divider, Fab } from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TipsList from '../../components/TipsListBusiness';
import TipsListEmployee from '../../components/TipsListEmployee';
const DashboardEmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");

 

  const [error, setError] = useState('')

  useEffect(()=>{
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);
  
    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {
  
      navigate(`${Path.employeeEdit}/`);
    }
  });


  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const invite = () => {
    navigate(`${Path.employeeInvite}/`);
  }
  const viewProfile = () => {

    navigate(`${Path.employeeViewProfile}/`);
  }

  const connectStripe = () => {

    navigate(`${Path.employeeStripe}/`);
  }
  const finalize = (
    <>




    
      <Box width="100%">
        <Typography gutterBottom variant="body1" component="div">
          Welcome {data?.name}
        </Typography>
        <Typography gutterBottom variant="h3" component="div">
          Dashboard
        </Typography>
       
        </Box>
        {data?.stripeverified === 0?
        <Button color="info" onClick={connectStripe} variant="roundedPurple">Set Up Payment</Button>
      :
<> </>
}
       
      <Box mt={2} width="100%" >

        <Grid container direction="row" spacing={2} >
          <Grid item width="50%">
          <Button startIcon={<PersonAddAlt1OutlinedIcon  sx={{color:"#6750A4"}} />} color="success" fullWidth type="submit" onClick={invite} variant="squarePurple">
          <Typography  variant="body1" sx={{color:"#6750A4"}}>Invite customers</Typography>
          </Button>
          </Grid>
          <Grid item width="50%">
          <Button startIcon={<AccountCircleOutlinedIcon  sx={{color:"#6750A4"}}/>}  color="success" fullWidth type="submit" onClick={viewProfile} variant="squarePurple">
          <Typography  variant="body1"  sx={{color:"#6750A4"}}>View Profile</Typography>
          </Button>
          </Grid>
        </Grid>
      </Box>
     
     
      <Box mt={2} width="100%">
<TipsListEmployee stripe={data?.stripeverified} />

</Box>
    
    </>
  )


  function showQR() {
    navigate(`${Path.employeeQR}/`);
}

return (

  <Grid  container direction="row" justifyContent="center" width="100%" alignItems="center">
      
      
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
          <Grid container direction="column" alignItems="center">
            
            {finalize}
          </Grid>
          <Box mt={2} width="100%" p={0}>
          <Button fullWidth onClick={showQR} variant="squareBlack">
          <QrCodeIcon />
          

<Typography variant="subtitle2" ml={1}>Share QR Code</Typography>
          </Button>
          </Box>
      </Grid>
    
    </Grid>
)
}

export default DashboardEmployee
