import React, { useState, useContext, useEffect } from 'react'
import http from "../../http-common";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { sendEmailBusinessComplete } from '../../libs/email';
import Paper from '@mui/material/Paper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Path } from "../../paths";
import {
  useEditBusinessMutation,
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Email } from '../../components/authComponents'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import { sendEmailBusinessStop } from '../../libs/email'

const StripeBusiness: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();
  //console.log("test")

  const { email, setEmail, emailIsValid } = useValidEmail('')
  const params = useParams<{ stripe: string }>();
  const authContext = useContext(AuthContext)
  const { data, isLoading } = useGetBusinessQuery(authContext.sessionInfo?.sub || "");
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
    } else {
      setComplete(true);
    }
  }, []);

  const [editBusiness] = useEditBusinessMutation();

  const form = useForm<Business>({

  })

  type Invite = {
    name: string,
    email: string
  }

  const { register, handleSubmit, watch } = form;
  const [error, setError] = useState('')

  const [verified, setVerified] = useState('');
  const [verifiedChecked, setVerifiedChecked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [connecting, setConnecting] = useState(false);

  const [verify, setVerify] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams();


  const stripeVerified = async (stripeID: string) => {
    if (!authContext.sessionInfo?.sub)
      return;
    if (!data)
      return
    try {
      //console.log(stripeID)

      let business: Business = {
        email: data.email,
        id: data.id,
        businessName: data.businessName,
        password: data.password,
        name: data.name,
        website: data.website,
        phone: data.phone,
        address: data.address,
        logoUrl: data.logoUrl,
        joinDate: data.joinDate,
        stripeid: stripeID,
        stripeverified: 1,
        bio: data.bio,
        social: data.social
      }


      await editBusiness(business).unwrap();
      sendEmailBusinessStop(data.email);
      //    navigate(`${Path.employeeFinalize}`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };

  const stripeNotVerified = async (stripeID: string) => {
    if (!authContext.sessionInfo?.sub)
      return;
    if (!data)
      return
    try {
      //console.log(stripeID)

      let business: Business = {
        email: data.email,
        id: data.id,
        businessName: data.businessName,
        password: data.password,
        name: data.name,
        website: data.website,
        phone: data.phone,
        address: data.address,
        logoUrl: data.logoUrl,
        joinDate: data.joinDate,
        stripeid: data.stripeid,
        stripeverified: 0,
        bio: data.bio,
        social: data.social
      }


      await editBusiness(business).unwrap();
      //    navigate(`${Path.employeeFinalize}`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };

  const verifyStripe = async () => {
    console.log("Hey")
    console.log(data?.stripeid)
    if (!data || data.stripeid === "" || data.stripeverified === 1 && verified === "true")
      return;
    console.log(data.stripeverified)

    const response = await fetch("https://tipzserver.com/backend/api/businesses/verified", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/verified", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        stripeid: data.stripeid,
      })
    })

    const dataRes = await response.json();
    console.log(dataRes)
    //console.log(dataRes.payouts_enabled)
    if (dataRes.payouts_enabled === true) {
      console.log("SUBMITTED2")
      setVerified("true")
      await stripeVerified(data.stripeid)
      console.log(data.stripeid)
      console.log(emailSent)
      if (searchParams.get("stripe") === "true") {
        if (!emailSent) {
          console.log("Send Email")
          sendEmailBusinessComplete(data.businessName || "", data?.email || "")
          navigate(`${Path.businessInvite}` + "?setup=true");
          setEmailSent(true);
        }
      }
      //stripeVerified();
    } else {
      setVerified("false")
      await stripeNotVerified(data.stripeid);

    }

  }

  useEffect(() => {

    console.log("Stripe Verified = " + data?.stripeverified)
    if (data?.stripeverified === 0) {

      verifyStripe();
    }


  }, [data]);


  if (isLoading) {
    return <span>Loading</span>;
  }

  if (!complete) {
    return <span>Profile Incomplete</span>
  }
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {
    stripeLogin();
    //console.log("edit");
  }
  //console.log(params.stripe);

  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }
  const editStripe = async () => {
    //console.log("Editing");
  }
  const stripeLogin = async () => {
    //console.log(data?.stripeid)
    if (!data || data.stripeid === "")
      return;

    const response = await fetch("https://tipzserver.com/backend/api/businesses/stripelogin", {
      //const response = await fetch("https://tipzserver.com/backend/api/employees/verified", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        stripeid: data.stripeid,
      })
    })

    const dataRes = await response.json();
    //console.log(dataRes)
    window.open(dataRes.url, "_blank")

  }

  const connectStripe = async () => {

    if (connecting)
      return;
    setConnecting(true);
    const response = await fetch("https://tipzserver.com/backend/api/businesses/createstripe", {
      //const response = await fetch("https://tipzserver.com/backend/api/businesses/createstripe", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const dataRes = await response.json();
    //console.log(dataRes.url)
    let splitString = dataRes.url.split("/")
    //console.log(splitString[splitString.length - 2])
    handleEditBusiness(splitString[splitString.length - 2])
    window.location.href = dataRes.url
  };


  const handleEditBusiness = async (stripeID: string) => {
    if (!authContext.sessionInfo?.sub)
      return;
    if (!data)
      return
    try {


      let business: Business = {
        email: data.email,
        id: data.id,
        businessName: data.businessName,
        password: data.password,
        name: data.name,
        website: data.website,
        phone: data.phone,
        address: data.address,
        logoUrl: data.logoUrl,
        joinDate: data.joinDate,
        stripeid: stripeID,
        stripeverified: data.stripeverified,
        bio: data.bio,
        social: data.social
      }


      await editBusiness(business).unwrap();
      //    navigate(`${Path.employeeFinalize}`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };




  const viewProfile = () => {

    navigate(`${Path.businessView}/`);
  }

  const finalize = (
    <>


      <Box width="100%" justifyContent="center">
        <Grid container direction="row" alignItems="top" justifyContent="center">
          <Box mt={1} mb={1} width="100%" justifyContent="center" textAlign='center'>
            <Box >
              <Grid container direction="column" alignItems="center">
                <Typography variant="h1" gutterBottom>Get Paid</Typography>
              </Grid>
            </Box>
            {
              (data && data.stripeverified === 1) || verified === "true" ?

                <Button disabled={true} sx={{
                  "&.Mui-disabled": {
                    background: "#ECECEC",
                    color: "#a6a6a6"
                  }
                }} color="info" onClick={editStripe} variant="roundedPurple">
                  <TaskAltOutlinedIcon />  <Typography ml={1} variant="subtitle2">
                    Stripe Connected</Typography>
                </Button>
                :
                connecting ? <Button color="info" disabled variant="roundedPurple">Connecting  </Button>
                  :
                  <Button color="info" onClick={connectStripe} variant="roundedPurple">Connect with Stripe</Button>
            }
          </Box>

        </Grid>

        <Grid container direction="row" alignItems="top" justifyContent="center">
          <Box mt={2} mb={1} width="100%" justifyContent="center" textAlign='center'>

            {
              (data && data.stripeverified === 1) || verified === "true" ?

                <Button disabled={false} sx={{
                  minWidth: "185px",
                  "&.Mui-disabled": {
                    background: "#ECECEC",
                    color: "#a6a6a6"
                  }
                }} color="info" onClick={onEdit} variant="roundedPurple">
                  <Typography ml={1} variant="subtitle2">
                    Open Stripe</Typography>
                </Button>
                :
                <></>
            }
          </Box>

        </Grid>
      </Box>

    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" justifyContent="center" item>
        <Grid container direction="column" justifyContent="center" alignItems="center">


          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StripeBusiness
