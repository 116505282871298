import React, { useState, useContext, useRef, useEffect } from 'react'

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Path } from "../../paths";
import Paper from '@mui/material/Paper'
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";
import AWS from 'aws-sdk';

import useFilePreview from "../../hooks/useFilePreview";
import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";



import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Avatar } from '@mui/material';
import ImageCrop from 'mui-image-crop';
import { ICroppedImage } from 'mui-image-crop/es/types';
import { sendEmailEmployeeSignup } from '../../libs/email'


function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const s3Config = {
  bucketName: 'tipzbucket',
  dirName: '',
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY || "",
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY || "",
  s3Url: ''
};


const EditEmployee: React.FunctionComponent<{}> = () => {

  const [croppedImage, setCroppedImage] = useState<ICroppedImage>();
  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  const authContext = useContext(AuthContext)

  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");
  ////console.log(authContext.sessionInfo?.sub)
  //console.log("Dat2=" + data?.id);
  const form = useForm<Employee>({

  })
  // u

  const [setup, setSetup] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const { register, handleSubmit, watch } = form;
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const logoUrlRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register('photoUrl')
  const [error, setError] = useState('')


  const [editEmployee] = useEditEmployeeMutation();


  if (searchParams.get("setup") === "true" && !setup) {
    setSetup(true)
  }

  const handleEditEmployee = async (employee: Employee) => {
    if (!authContext.sessionInfo?.sub)
      return;
    try {
      //sendEmailEmployeeSignup(data!.email!);
      let v = 0;
      if (data) {
        if (data.photoUrl.includes(".v")) {
          let vs = data.photoUrl.split('.')
          //console.log(vs)
          let version = vs[vs.length - 2];
          //console.log(version)
          if (version.includes("NaN")) {
            v = 0;
          } else {
            let versionNumber = Number(version.replace("v", ""))
            //console.log(versionNumber);
            v = versionNumber+1;
          }
        }
      }
      if (croppedImage) {

        ////console.log("https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo?.sub + "" + fileExt)

        employee.photoUrl = "https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo?.sub + ".v" + v + ".png";

      } else {
        if (data) {
          employee.photoUrl = data.photoUrl;
        } else {
          employee.photoUrl = "";
        }
        // "https://dpcy146y2wbkr.cloudfront.net/" + authContext.sessionInfo?.sub + "" + fileExt
      }
      employee.id = authContext.sessionInfo?.sub;
      if (data) {
        employee.userId = data?.userId

      }
      const editedEmployee = {
        ...data,
        ...employee,

      };
      await authContext.setProfile(employee.name)
      await authContext.setProfileFinished(employee.name)

      //console.log(employee)
      await editEmployee(editedEmployee).unwrap();

      if (croppedImage) {
        
        await uploadImage(authContext.sessionInfo.sub + ".v" + v + ".png")


      }
     
      
        navigate(`${Path.employeeFinalize}`);
      
        

    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };

  if (isLoading) {
    return <span>Loading</span>;
  }


  const onSubmit = (data: Employee) => {
    //console.log(data);
  }



  const handleClick = () => {
    logoUrlRef.current?.click()
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    //console.log(event.target.files)
    if (!event.target.files || event.target.files.length === 0) return


    setSelectedFile(event.target.files[0]);

  }

  const uploadImage = async (filename: string) => {
    if (!croppedImage)
      return;

    let img = document.createElement('img');
    img.src = croppedImage.url
    ////console.log(croppedImage.url);
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    if (!ctx)
      return;
    ctx.drawImage(img, 0, 0);

    let width = img.width;
    let height = img.height;
    let max_dimension = 1024;
    if (width > height) {
      if (width > max_dimension) {
        height *= max_dimension / width;
        width = max_dimension;
      }
    }
    else {
      if (height > max_dimension) {
        width *= max_dimension / height;
        height = max_dimension;
      }
    }
    canvas.width = width;
    canvas.height = height;

    ctx = canvas.getContext("2d");
    if (!ctx)
      return;
    ctx.drawImage(img, 0, 0, width, height);
    let extension = ".png";
    let type = "image/" + extension;
    let data_url = canvas.toDataURL(type);
    const file = dataURLtoFile(data_url, filename);

    //console.log(file);
    AWS.config.update({
      accessKeyId: s3Config.accessKeyId,
      secretAccessKey: s3Config.secretAccessKey,
    });

    const s3 = new AWS.S3({
      params: { Bucket: s3Config.bucketName },
      region: s3Config.region,

    });

    const params = {
      Bucket: s3Config.bucketName,
      Key: filename,
      Body: file,
      ContentType: 'image/png',
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        //console.log("uploading");
      })
      .promise();

    await upload.then((err) => {
      //console.log(err);
      // alert("File uploaded successfully.");
    });

  }


  const hello = (
    <>

      <Box justifyItems="center" justifyContent='center' alignItems="center" alignContent="center" width="100%" >
        <form onSubmit={handleSubmit(handleEditEmployee)}>
          <input type="file" style={{ display: "none" }} accept="image/*"
            {...register("photoUrl", { onChange: (e) => { handleChange(e) }, })}
            {...rest} ref={(e) => {
              ref(e)
              logoUrlRef.current = e
            }}
            id="logoUrl" />
          <Grid container justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center">
            <Grid item justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center">

              <Box width="100%" justifyItems="center" textAlign="center" justifyContent='center' alignItems="center" alignContent="center" >

                <ImageCrop

                  value={croppedImage}
                  onChange={setCroppedImage}
                  defaultAspect={1 / 1}
                  aspect={1 / 1}
                  showAspectToolbar={false}
                  preview={false}
                  uploaderProps={{
                    style: {
                      border: 'none',
                      width: 'auto',
                      height: 'auto',
                    },
                  }}
                >

                  {
                    (croppedImage?.url === "" || croppedImage === null || croppedImage === undefined)
                      ?
                      data?.photoUrl ?
                        <Avatar sx={{ height: "128px", width: "128px" }} src={data?.photoUrl} />

                        :
                        <Box bgcolor={'#F9F9F9'} p={2} sx={{ borderRadius: 3 }} height={'150px'} justifyContent="center" alignItems={'center'}>


                          <Grid container justifyContent="center" height="100%" alignItems={'center'} direction="column">
                            <Grid item justifyContent="center" alignItems={'center'}>
                              <FileUploadOutlinedIcon sx={{ color: "#B3B1B1" }} /></Grid>
                            <Grid item justifyContent="center" alignItems={'center'}>
                              <Typography variant="h3" color={"#B3B1B1"} >Profile Photo</Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      :
                      <Avatar sx={{ height: "128px", width: "128px" }} src={croppedImage?.url} />

                  }


                </ImageCrop>
              </Box>
            </Grid>
          </Grid>
          <Box mt={1} mb={1} width="100%">
            <TextField
             inputProps={{
              maxLength: 50,
            }}
              fullWidth
              type="text"
              {...register("name", { value: data?.name })}
              label={'Name'} />

          </Box>
          <Box mt={1} mb={1} width="100%">
            <TextField
              fullWidth
              inputProps={{
                maxLength: 50,
              }}
              type="text"
              {...register("title", { value: data?.title })}
              variant="outlined"
              label={'Job Title'}
            />
          </Box>

          <Box mt={1} mb={1} width="100%">
            <TextField
              fullWidth
              inputProps={{
                maxLength: 300,
              }}
              type="text"
              variant="outlined"
              {...register("bio", { value: data?.bio })}
              label={'Bio (Optional)'}
            />
          </Box>

          <Box mt={1} mb={1} width="100%">
            <TextField
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
              type="text"
              variant="outlined"
              {...register("social", { value: data?.social })}
              label={'Link to Social (Optional)'}
            />
          </Box>
          <Box mt={2}>
            <Typography color="error" variant="body1">
              {error}
            </Typography>
          </Box>

          {/* Buttons */}
          <Box width="100%" justifyContent="center" alignContent="center">
            <Grid container direction="row" justifyContent="center" alignContent="center" >
              <Box width="100%" justifyContent="center" alignContent="center">
                <Button fullWidth color="primary" type="submit" variant="roundedBlack">
                  Save
                </Button>
              </Box>

            </Grid>
          </Box>
        </form>
      </Box>
    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box >
            <Grid container direction="column" alignItems="center">
              <Typography variant="h1" gutterBottom>Say Hello</Typography>
              <Typography variant="subtitle1">Create your client facing profile.</Typography>
            </Grid>
          </Box>

          {hello}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditEmployee
