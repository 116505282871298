import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidUsername } from '../../hooks/useAuthHooks'
import { Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'


export default function RequestCode() {

  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const [error, setError] = useState('')
  const [resetSent, setResetSent] = useState(false)

  const isValid = !usernameIsValid || username.length === 0
  const [email, setEmail] = useState("");
  const navigate = useNavigate()

  const [queryParameters] = useSearchParams()
  const authContext = useContext(AuthContext)

  useEffect(() => {

    console.log(queryParameters.get("email"))

    if (queryParameters.get("email")){
      setEmail(queryParameters.get("email") || "")
      setUsername(queryParameters.get("email") || "")
    }


  }, [authContext]);

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username)
      setResetSent(true)
    } catch (err) {
      setError('Unknown user')
    }
  }

  const emailSent = (
    <>
      <Box mt={1}>
        <Typography sx={{ textAlign: 'center' }} variant="h5">{`Reset Email Sent to ${username}`}</Typography>
      </Box>
      <Box mt={4}>

        <Typography sx={{ textAlign: 'center' }} variant="h5">{`Please click link  to change your password.`}</Typography>
      </Box>
    </>
  )


  const sendCode = (
    <>
      <Box width="80%" mt={1} mb={1}>
        {email!=""?
        <Username usernameIsValid={usernameIsValid} value={email} setUsername={setUsername} />
        :
        <Username usernameIsValid={usernameIsValid}  setUsername={setUsername} />
  }
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" >
          <Box mt={1} mb={1}>
            <Button color="secondary" variant="squareOutline" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Box>
          <Box mt={1} mb={1}>
            <Button disabled={isValid} sx={{
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0"
              }
            }} color="primary" variant="squareBlack" onClick={sendCodeClicked}>
              Send Email
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          <Box mt={2} mb={2}>
            <Typography variant="h3">Send Reset Link</Typography>
          </Box>

          {resetSent ? emailSent : sendCode}
        </Grid>
      </Grid>
    </Grid>
  )
}
