import { Business } from "@prisma/client";
import { api } from "./api";

export const businessesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBusinesses: builder.query<Business[], void>({
      query: () => ({
        url: "/businesses",
        method: "GET",
      }),
    }),
    getBusiness: builder.query<Business, string>({
      query: (id) => ({
        
        url: `/businesses/${id}`,
        method: "GET",
      }),
    }),
    editBusiness: builder.mutation<string, Business>({
      query: (business) => ({
        url: `/businesses/edit/${business.id}`,
        method: "PUT",
        body: business,
      }),
    }),
    removeBusiness: builder.mutation<string, string>({
      query: (id) => ({
        url: `/businesses/remove/${id}`,
        method: "DELETE",
        body: { id },
      }),
    }),
    addBusiness: builder.mutation<Business, Business>({
      query: (business) => ({
        url: `/businesses/add`,
        method: "POST",
        body: business,
      }),
    }),
  }),
});

export const {
  useGetAllBusinessesQuery,
  useGetBusinessQuery,
  useEditBusinessMutation,
  useRemoveBusinessMutation,
  useAddBusinessMutation,
} = businessesApi;
export const {
  endpoints: {
    getAllBusinesses,
    getBusiness,
    editBusiness,
    removeBusiness,
    addBusiness,
  },
} = businessesApi;
