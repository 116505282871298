import React, { useState, useContext, useEffect } from 'react'
import 'aws-amplify/auth/enable-oauth-listener';
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
const Redirect: React.FunctionComponent<{}> = () => {


  useEffect(() => {
    window.location.href = "https://tipzserver.com"
    console.log("Redirected");

  },[])






  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      
    </Grid>
  )
}

export default Redirect
