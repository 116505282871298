import React from 'react'

import { useNavigate, Link } from 'react-router-dom'

import { makeStyles } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'



const Landing: React.FunctionComponent = () => {

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/signin')
  }
  const searchBusiness = () => {

    // navigate('/signout')
    navigate('/searchBusiness')
  }
  const signUpBusiness = () => {
    navigate('/signupbusiness')
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="top" item>


        <Box mt={0} >
          <Grid mt={0} container direction="row" alignItems="center">
            <Box mt={0} width="100%" textAlign="center" justifyContent="center">
              <img height={'223px'} width={'292px'} src="https://dpcy146y2wbkr.cloudfront.net/img2.png" /></Box>
            <Box pt={2} width="100%" textAlign="center" justifyContent="center">
              <Typography gutterBottom align="center" sx={{ fontSize: "44px" }} variant="h6">
                Tipz
              </Typography>
            </Box>

            <Box  mb={1} width="100%" textAlign="center" justifyContent="center">
              <Typography gutterBottom align="center" variant="h4">
              Stop tipping tablets, start tipping people.
Show your gratitude and find who you want to thank in a few easy clicks.
              </Typography>
            </Box>


            <Box mt={2}  width="100%">
              <Button onClick={searchBusiness} fullWidth variant="roundedPurple" >
                <Typography variant="subtitle2">Search for a business</Typography>
              </Button>
            </Box>
            <Box mt={1} mb={1} width="100%">
              <Button onClick={signIn} fullWidth sx={{ color: "#6750A4" }} variant="roundedGrey">
                <Typography variant="subtitle2">Sign in</Typography>
              </Button>
            </Box>
            <Grid container justifyContent="center" justifyItems="center" alignItems="center">
              <Grid item justifyContent="center" justifyItems="center" alignItems="center">
                <Box mt={1} mb={1} width="100%" textAlign="center" justifyContent="center" justifyItems="center" alignItems="center">
                  <Typography gutterBottom align="center" variant="subtitle1">
                    Are you a business or employee who wants to join Tipz?
                  </Typography>

                  <Typography gutterBottom sx={{ textDecoration: "none" }} align="center" variant="subtitle2">

                    <Link style={{color:"#000000"}} to="/create">Create an account</Link>
                  </Typography>   <Typography gutterBottom sx={{ textDecoration: "none" }} align="center" variant="body1">

<Link style={{color:"#000000"}} to="/faq">FAQ</Link>
</Typography>

                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Landing
