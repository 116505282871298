// Share.tsx

import { type FC, useState } from "react";

import ShareController from "./shareController";
import SharePopup from "./sharePopup";

const Share: FC<Props> = ({
  children,
  shareData,
  fullWidth,
  fixed,
  onInteraction,
  onSuccess,
  onError,
  disabled
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleNonNativeShare = () => {
    setOpenPopup(true);
  };

  return (
    <>
      <ShareController
        shareData={shareData}
        fullWidth={fullWidth}
        fixed={fixed}
        onInteraction={onInteraction}
        onSuccess={onSuccess}
        onError={onError}
        onNonNativeShare={handleNonNativeShare}
        disabled={disabled}
      >
        {children}
      </ShareController>
      {openPopup ? (
        <SharePopup
          shareData={shareData}
          onClose={() => setOpenPopup(false)}
        />
      ) : null}
    </>
  );
};

interface Props {
  children: React.ReactNode;
  shareData: ShareData;
  fullWidth?:boolean;
  fixed?:boolean;
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  onInteraction?: () => void;
  disabled?: boolean;
}

export default Share;