import { Box, Grid, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaymentStatus from '../../utils/stripe/payment-status';

const nodeEnv: string = (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const stripePromise = loadStripe(nodeEnv);


const FAQ = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  useEffect(() => {
    const newParams: { [key: string]: string } = {};

    // setSearchParams(newParams);
    //  navigate({
    //    search: createSearchParams(newParams).toString(),
    //}, {replace: true});
  })
  //console.log("Thankyou");
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%', width: '100%' }} container direction="row" alignItems="center" item>
        <Grid width="100%" container direction="column" alignItems="center" >
          <Box >
            <Grid container direction="column" alignItems="center">
              <Typography variant="h1" gutterBottom>FAQ</Typography>
            </Grid>
          </Box>
          <Grid sx={{ backgroundColor: '#000000' }} width="100%" container direction="row">
            <Grid xs={3} item color="#ffffff">
              <Typography color="#ffffff" gutterBottom variant="h3">Q</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography color="#ffffff" gutterBottom variant="h3">A</Typography>
            </Grid>
          </Grid>

          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>What is Tipz?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>Tipz was created out of frustration with modern tipping practices, which have become even more complicated since COVID. We're tipping more than ever, but who and why we're tipping hasn't evolved. Have you ever wanted to show gratitude to someone who went out of their way for you but couldn't because you lacked cash, didn't know their name or Venmo, or felt uncomfortable asking for their details? These barriers often prevent us from expressing appreciation. Some businesses don't traditionally accept tips either. With Tipz, we can choose who to tip, when we want, without being pressured into tipping situations where the destination of our money is unclear. Our goal is to establish a new standard of tipping, recognizing hard work and updating the outdated mentality around gratuity.</Typography>
            </Grid>
          </Grid>

          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>How does Tipz work?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>"Businesses and employees can sign up for Tipz by visiting mytipz.co. Just follow the prompts to create you company or personal profile, and connect with Stripe in a few easy clicks. You'll be ready to go in just a couple minutes.
<br />
If you're looking for someone to tip, start by finding their business. There's no app, no download, and no need to create a login. Just find your recipient in the business directory, send them a note if you like, and send your desired amount. "</Typography>
            </Grid>
          </Grid>

          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>How can I get in touch?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>Questions? Feedback? You can reach us at roxy@mytipz.co.</Typography>
            </Grid>
          </Grid>

          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>Is my data safe?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>Data and privacy are a top priority. We've made sure to partner with industry leaders AWS and Stripe to ensure world class security and privacy standards.</Typography>
            </Grid>
          </Grid>

          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>What about tax documents?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>Because we work with Stripe, you'll receive all necessary tax documents as a tip recipient.</Typography>
            </Grid>
          </Grid>


          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>Why are there fees?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>The fees charged by Tipz help us cover credit card and Stripe fees. Most transactions end up costing us more than we're taking with our fee, but it still helps us offset the costs incurred to run this small business.</Typography>
            </Grid>
          </Grid>


          <Grid width="100%" container direction="row">
            <Grid xs={3} item>
              <Typography align="left" gutterBottom variant="h5" sx={{ wordWrap: 'break-word' }}>I'm tired of tipping, why does this exist?</Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography align="left" gutterBottom variant="body1" sx={{ wordWrap: 'break-word' }}>Everywhere you look, there's a new article or complaint about new tipping culture. We're frustrated because we're being asked to provide money for something we don't understand. There's a feeling of pressure or shame for not tipping, even if extra effort isn't being put into a task. Even if you spend a few dollars a week on tips you don't feel good about, we want to create a solution that makes tipping about showing gratitude to people who truly make our days. Instead of tipping Starbucks $2 on your $7 latte, you can now find your local yoga teacher or small business to say thank you to, when you want. It's a new way of saying thank you.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default FAQ;
