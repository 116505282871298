import React from "react";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";

config.oauth.domain = "login.tipzserver.com"
Amplify.configure(config, { ssr: true });

export default function ConfigureAmplifyClientSide() {
   // //console.log("AMPLIFY")
  return null;
}