import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, getCurrentUser, AuthUser } from "aws-amplify/auth";
import { Divider } from '@mui/material'

const SignIn: React.FunctionComponent<{}> = () => {
  const accountType = "business"
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [userVerified, setUserVerified] = useState(false);
  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  /*Hub.listen("auth", async ({ payload }) => {
    switch (payload.event) {
      case "signInWithRedirect":
        const user = await getCurrentUser();
        //console.log(user.username);
        break;
      case "signInWithRedirect_failure":
        // handle sign in failure
        //console.log("Sign In Failed")
        break;
      case "customOAuthState":
        const state = payload.data; // this will be customState provided on signInWithRedirect function
        //console.log(state);
        break;
    }
  });*/



  const authContext = useContext(AuthContext)
  const sign = async()=>{
    await authContext.signOut();
    
    navigate('/signin?verified=true')
  }

  useEffect(() => {

    if (searchParams.get("verified") === "true") {
      setUserVerified(true);
      console.log("Set User verified 2!");
      sign();
    }
  }, [])

  function handleSignInGoogleClick(customState: string) {
    signInWithRedirect({
      provider: "Google",
      customState
    });
  }
  function handleSignInFacebookClick(customState: string) {
    signInWithRedirect({
      provider: "Facebook",
      customState
    });
  }
  function handleSignInAppleClick(customState: string) {
    signInWithRedirect({
      provider: "Apple",
      customState
    });
  }
  const clientId = "879387739752-bqb8ghakcd0jeua6agiae4he36ts0nk9.apps.googleusercontent.com"
  const onSuccess = (res: any) => {
    //console.log(res.profileObject);
  }
  const onFailure = (res: any) => {
    //console.log(res)
  }
  const signInClicked = async () => {
    try {
      await authContext.signInWithEmail(username, password)
      navigate('/')
    } catch (err: any) {
      //console.log("ERROR"+err)
      if (err.code === 'UserNotConfirmedException') {
        navigate('/verify')
      } else {
        setError(err.message)
      }
    }
  }
  const passwordResetClicked = async () => {
    navigate('/requestcode')
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" style={{ height: '100%' }} alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box mt={1} mb={2}>
            <Typography variant="h3">Sign in</Typography>
          </Box>
          {userVerified ?
            <Box>
              <Typography gutterBottom sx={{ cursor: 'pointer' }} variant="subtitle1">
                Your email has been verified! Please Sign In.
              </Typography>
            </Box>
            :
            <></>
          }
          {/* Sign In Form */}
          <Box width="100%" mt={1} mb={1}>
            {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />{' '}
          </Box>
          <Box width="100%">
            <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
            <Grid container direction="row" alignItems="center">
              <Box onClick={passwordResetClicked} mt={2}>
                <Typography sx={{ cursor: 'pointer' }} variant="body1">
                  Forgot Password?
                </Typography>
              </Box>
            </Grid>
          </Box>

          {/* Error */}
          <Box mt={1}>
            <Typography color="error" variant="body1">
              {error}
            </Typography>
          </Box>

          {/* Buttons */}
          <Box mt={1} mb={1} width="100%">
            <Grid container direction="row" >

              <Box width="100%" mt={1} mb={1}>
                <Button disabled={isValid} sx={{
                  "&.Mui-disabled": {
                    background: "#eaeaea",
                    color: "#c0c0c0"
                  }
                }} fullWidth color="primary" variant="squareBlack" onClick={signInClicked}>
                  Sign In
                </Button>
              </Box>
            </Grid>
            <Box mt={1} mb={1} width="100%">
              <Divider orientation="horizontal" flexItem />
            </Box>
            <Grid container direction="row" >

              <Box mt={1} mb={1} width="100%">

                <Button onClick={() => { handleSignInGoogleClick("") }} sx={{ textTransform: "none" }} startIcon={<GoogleIcon />} fullWidth variant="squareOutline">
                  Sign In with Google
                </Button>
              </Box>
              <Box mt={1} mb={1} width="100%">

                <Button onClick={() => { handleSignInFacebookClick("") }} sx={{ textTransform: "none" }} startIcon={<FacebookIcon />} fullWidth variant="squareOutline">
                  Sign In with Facebook
                </Button>
              </Box>

            </Grid>
            <Box mt={1} mb={1} width="100%">
              <Divider orientation="horizontal" flexItem />
            </Box>
            <Grid container direction="row" >
              <Box width="100%" mt={1} mb={1}>
                <Button color="primary" fullWidth variant="squareOutline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Box>

            </Grid>
          </Box>
          <Box mt={2}>
            <Box onClick={() => navigate('/create')}>
              <Typography sx={{ cursor: 'pointer' }} variant="subtitle1">
                Register a new account
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignIn
