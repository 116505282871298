import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";

import {
  useRemoveEmployeeMutation,
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase, Drawer, Stack } from '@mui/material';

const ViewEmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  const [removeEmployee] = useRemoveEmployeeMutation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(params.id || authContext.sessionInfo?.sub || "");
  useEffect(() => {
    if (!params.id) {
      if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
        navigate(`${Path.businessEdit}/`);

      } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

        navigate(`${Path.employeeEdit}/`);
      }
    }
  });


  const [error, setError] = useState('')




  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    navigate(`${Path.employeeEdit}/`);
  }
  const onInvite = () => {

    navigate(`${Path.employeeInvite}/`);
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }
  const deleteAccount = async () => {
    if (data) {
      
      await authContext.deleteUser();
      console.log("deleting Account");
      removeEmployee(data?.id!).unwrap();
      handleClose()
      authContext.signOut();
      navigate(`${Path.home}/`)
    }
  }

  const finalize = (
    <>




      <Box mt={1} width="100%">

        <Paper elevation={0} variant="outlined"
          sx={{
            p: 1,
            width: "100%",
            borderRadius: "24px",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid  container direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
            <Grid item>
              <ButtonBase sx={{ width: 110, height: 110 }}>
              <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.photoUrl} />

              </ButtonBase>
            </Grid>

            <Grid xs={12} item container alignItems="center"  width="70%" >
              <Grid xs item container alignItems="left" p={1} direction="column" >
                <Grid width="100%" item>
                  <Typography align="left" gutterBottom variant="h5" component="div">
                    {data?.name}
                  </Typography>
                </Grid>
                <Grid width="100%" item>
                  <Typography align="left" variant="subtitle1" gutterBottom>
                    {data?.title}
                  </Typography>
                </Grid>
                <Grid width="100%" item>
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    <Link style={{ color: "#000000" }} to={data ? data?.social : ""} > {data?.social}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>




        {(data?.bio !== "" || data.social !== "") ?
          <Box mt={2} width="100%">
            <Paper elevation={0} variant="outlined"
              sx={{
                p: 1,
                margin: 'auto',
                borderRadius: "24px",
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
               <Grid item xs={12} sm container>
              <Grid item xs p={2} direction="column" justifyItems="left" alignItems="left" >
                
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    {data?.bio}
                  </Typography>
                
                  {data?.social ?
                    <Typography variant="subtitle1" color="text.secondary">
                      Follow {data?.name} at: <Link style={{ color: "#000000" }} to={data ? data.social.includes("http") ? data.social : "https://" + data.social  : ""} >{data?.social}</Link>
                    </Typography>
                    : <></>
                  }
                  
                </Grid>
              </Grid>
            </Paper>



          </Box>
          :
          <></>
        }
      </Box>
      {params.id ?
        <Box mt={1} width="100%" justifyContent="center" textAlign='center'>
          <Link to={data?.stripeverified ? '/employee/tip/' + data?.id : "#"}>
            {data?.stripeverified ?
              <Button sx={{ minWidth: "150px" }} color="info" variant="roundedPurple">Tip {data?.name}</Button>
              :
              <Button sx={{ minWidth: "150px" }} color="info" variant="roundedBlack">Not Verified</Button>
            }
          </Link>
        </Box>
        :
        <Box></Box>

      }

      <Box width="100%" mt={1} >
        {params.id ?
          <Grid container width="100%" direction="row" >

          </Grid>
          :
          <Grid container width="100%" direction="column" alignItems="center">
            <Grid item width="100%" justifyContent="center">

              <Button fullWidth color="info" type="submit" onClick={onInvite} variant="roundedPurple">
                Invite Clients
              </Button>
            </Grid>

            <Grid mt={1} width="100%" item justifyContent="center">

              <Button fullWidth color="primary" type="submit" onClick={onEdit} variant="roundedBlack">
                Edit
              </Button>
            </Grid><Grid mt={1} width="100%" item justifyContent="center">

              <Button fullWidth color="primary" type="submit" onClick={handleOpen} variant="roundedBlack">
                Delete Account
              </Button>
            </Grid>

            <Drawer open={open}
              onClose={handleClose} anchor="bottom">

              <Box p={4} sx={{ backgroundColor: "#ff0000" }}>
                <Typography id="modal-modal-title" color="white" variant="h3" align="center" component="h2">
                  Delete your account?
                </Typography>
                <Stack direction="row"
                  justifyContent="center"
                  alignItems="center" spacing={1}>
                  <Typography color="white" align="center" id="modal-modal-description" variant="h5" sx={{ mt: 2 }}>
                    Are you sure you want to delete your account? Your login and data will be removed from Tipz.
                  </Typography>

                </Stack>
                <Box width="100%" mt={1} mb={1}>
                  <Button fullWidth color="secondary" sx={{ textTransform: "none", borderRadius: "100px", padding: "8px 22px" }} type="submit" onClick={deleteAccount} variant="roundedBlack">
                    <Typography variant="subtitle2">Delete Account</Typography>
                  </Button>
                </Box>
                <Box width="100%" mt={1} mb={1}>
                  <Button fullWidth color="secondary" sx={{ textTransform: "none", borderRadius: "100px", padding: "8px 22px" }} type="submit" onClick={handleClose} variant="contained">
                    <Typography variant="subtitle2">Cancel</Typography>
                  </Button>
                </Box>


              </Box>
            </Drawer>
          </Grid>
        }
      </Box>
    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {params.id ?
            <></> :
            <Box  >
              <Grid container direction="column" alignItems="center">
                <Typography variant="h1" >Profile</Typography>
              </Grid>
            </Box>

          }
          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewEmployee
