import React, { useState, useContext, useEffect } from 'react'
import http from "../../http-common";
import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import {
  useEditBusinessMutation,
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Email } from '../../components/authComponents'
import { sendEmailInvite } from '../../libs/email';
import Share from '../../utils/share';

const InviteBusinessClients: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const { email, setEmail, emailIsValid } = useValidEmail('')
  const params = useParams<{ id: string }>();
  const authContext = useContext(AuthContext)
  const { data, isLoading } = useGetBusinessQuery(authContext.sessionInfo?.sub || "");


  useEffect(()=>{
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);
  
    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {
  
      navigate(`${Path.employeeEdit}/`);
    }
  });
  const form = useForm<Invite>({

  })
  const { register, handleSubmit, watch, reset,
    formState,
    formState: { isSubmitSuccessful }
  } = form;
  type Invite = {
    name: string,
    email: string
  } 
  
  const isValid =
  !emailIsValid ||
  email.length === 0

const isEmpty =
  email.length === 0
const [error, setError] = useState('')
const [sent, setSent] = useState(false);

useEffect(() => {
  //console.log(sent +" "+isEmpty+ " "+isValid)
  if (isValid){
    setSent(false);
//console.log("Finished +"+sent)
  }
});
React.useEffect(() => {
  if (formState.isSubmitSuccessful) {
    setSent(true);
    reset()
  }
}, [formState, reset]);



  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }
  const shareData = {
    title: "Share",
    text: "I'm now on Tipz, a new platform that allows you to discover people and businesses you want to tip on your terms. Check it out!",
    url: "https://tipzserver.com/business/"+data?.id
  }

  const notifyEmployee = async(invite: Invite) => {

    sendEmailInvite(invite.name, invite.email)
  }
  const notifyEmployee2 = (invite: Invite) => {

    return http.post("/email/sendinvite", data);
  }

  const finishSetup = () => {

    if (data?.stripeverified)
      navigate(`${Path.searchBusiness}/`);
    else
      navigate(`${Path.businessFinish}/`);
  }

  const finalize = (
    <>


<Grid container width="100%" direction={"column"} alignItems="center" justifyItems="center" >
        <Grid item container direction="column" width="100%" alignContent="center" justifyContent="center">
          <Grid item width="100%" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
            <form onSubmit={handleSubmit(notifyEmployee)}>

              <Box width="100%" mt={1} mb={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  {...register("name")}
                />
              </Box>
              <Box width="100%" mt={1} mb={1}>
              <TextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  {...register("email")}
                  onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setEmail(evt.target.value)
                  }}
                />
              </Box>
              <Grid item width="100%">
                <Box width="100%" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                  <Button disabled={isValid||isEmpty} fullWidth type="submit" variant="roundedPurple">
                    <PersonAddAltOutlinedIcon></PersonAddAltOutlinedIcon><Typography ml={1} variant="subtitle2"> Invite
                    </Typography>                  </Button>
                </Box>
                {sent ?
                  <Box width="100%" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                    <Typography align="center" variant="subtitle2">Sent
                    </Typography>
                  </Box>
                  : <></>

                }
              </Grid>
            </form>
            <Grid item width="100%" mt={1} mb={1}>
              <Box width="100%" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
                <Share fullWidth={true} shareData={shareData}>
                  <span>Share Tipz</span>
                </Share>
              </Box>
            </Grid>

          </Grid>

        </Grid>

      </Grid>


    </>
  )


  return (
    <Grid style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="top">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="top" item>
        <Grid container direction="column" width="100%" alignItems="center">
          {/* Title */}
          <Box >
            <Grid container direction="column" alignItems="center">
              <Typography variant="h1" gutterBottom>Invite Clients</Typography>
            </Grid>
          </Box>

          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InviteBusinessClients
