import React, { useState, useContext, useEffect, useRef } from 'react'
import QRReader from 'react-qr-reader';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import QRCodeStyling from "qr-code-styling";

import {
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import { QRCodeSVG } from 'qrcode.react';
import Share from '../../utils/share';
import { Avatar } from '@mui/material';
const QRBusiness: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetBusinessQuery(authContext.sessionInfo?.sub || "");



  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);
      return;

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
      return;
    }
  });


  const shareData = {
    title: "Share",
    text: "I'm now on Tipz, a new platform that allows you to discover people and businesses you want to tip on your terms. Check it out!",
    url: "https://tipzserver.com/business/"+data?.id
  }
  const [error, setError] = useState('')
  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
      dotsOptions: {
        color: "#000000",
        type: "extra-rounded"
      },
      cornersSquareOptions: {
        color: "#000000",
        type: "extra-rounded"
      },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 20
    }
  });

  const [url, setUrl] = useState("https://tipzserver.com/business/view/" + data?.id);
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  useEffect(() => {
    qrCode.append(ref.current||undefined);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);


  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }
  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }


  const inviteClients = () => {

    navigate(`${Path.businessInviteClients}/`);
  }
  const finalize = (
    <>




      {/* Buttons */}
      <Box  p={1} width="100%" >

<Grid container textAlign="center" justifyContent="center" direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
            <Grid item textAlign="center" justifyContent="center">
        <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.logoUrl} />

        </Grid>
        </Grid>



</Box>
      <Box width="100%">
        <Typography gutterBottom variant="h3" component="div">
          {data?.businessName}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {data?.address}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {data?.website}
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {data?.phone}
        </Typography>
      </Box>

      <Grid item container justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
        <Box  width="100%" justifyItems="center" alignItems="center" justifyContent="center" alignContent="center" textAlign="center">
         
            <Box width="100%"  justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
          <div ref={ref} />
          </Box>
          
      <Grid item mt={1}  direction="row" justifyContent="center" alignItems="center" >
          <Button fullWidth onClick={inviteClients} variant="roundedPurple">
            Invite Clients
          </Button>
        </Grid>
        <Grid mt={1} item direction="row" justifyContent="center" alignItems="center" >
          <Share  fullWidth fixed={true} shareData={shareData}>
            Share Tipz
          </Share>
        </Grid>
      </Box>
      </Grid>
      

    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          <Box  >
            <Typography align="center" variant="h3" gutterBottom>My QR Code</Typography>
          </Box>

          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QRBusiness
