import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { useValidPassword } from '../../hooks/useAuthHooks'
import { Password } from '../../components/authComponents'

import { AuthContext } from '../../context/authContext'


export default function ChangePassword() {

  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)

  const {
    password: oldPassword,
    setPassword: setOldPassword,
    passwordIsValid: oldPasswordIsValid,
  } = useValidPassword('')

  const {
    password: newPassword,
    setPassword: setNewPassword,
    passwordIsValid: newPasswordIsValid,
  } = useValidPassword('')

  const isValid = !oldPasswordIsValid || oldPassword.length === 0 || !newPasswordIsValid || newPassword.length === 0

  const navigate = useNavigate()

  const authContext = useContext(AuthContext)

  const changePassword = async () => {
    try {
      await authContext.changePassword(oldPassword, newPassword)
      setReset(true)
    } catch (err: any) {
      setError(err.message)
    }
  }

  const signOut = async () => {
    try {
      await authContext.signOut()
      navigate('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" mt={1} mb={1}>
        <Password label="Old Password" passwordIsValid={oldPasswordIsValid} setPassword={setOldPassword} />
      </Box>
      <Box width="80%" mt={1} mb={1}>
        <Password label="Password" passwordIsValid={newPasswordIsValid} setPassword={setNewPassword} />
      </Box>
      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row">
          <Box mt={1} mb={1}>
            <Button onClick={() => navigate(-1)} color="secondary" variant="squareOutline">
              Cancel
            </Button>
          </Box>
          <Box mt={1} mb={1}>
            <Button sx={{
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0"
              }
            }} disabled={isValid} color="primary" variant="squareBlack" onClick={changePassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Changed`}</Typography>

      <Box mt={4} mb={4}>
        <Button onClick={signOut} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <Grid sx={{ height: "100%" }} container direction="row" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          {/* Title */}
          <Box mt={3} mb={3}>
            <Grid container direction="row" alignItems="center">
              <Typography variant="h3">Change Password</Typography>
            </Grid>
          </Box>

          {!reset ? updatePassword : passwordReset}
        </Grid>
      </Grid>
    </Grid>
  )
}
