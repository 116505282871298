import React, { useState, useContext, useEffect, useRef } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import QRCodeStyling from "qr-code-styling";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import {
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { QRCodeSVG } from 'qrcode.react';
import { Avatar, ButtonBase } from '@mui/material';
import Share from '../../utils/share';



const QREmployee: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");

  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);
      return;

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
      return;
    }
  });

  const shareData = {
    title: "Share",
    text: "I'm now on Tipz, a new platform that allows you to discover people and businesses you want to tip on your terms. Check it out!",
    url: "https://tipzserver.com/employee/"+data?.id
  }


  const [error, setError] = useState('')



  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    
    dotsOptions: {
      color: "#000000",
      type: "extra-rounded"
    },
    cornersSquareOptions: {
      color: "#000000",
      type: "extra-rounded"
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 10
    }
  });

  const [url, setUrl] = useState("https://tipzserver.com/business/view/" + data?.id);
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  useEffect(() => {
    qrCode.append(ref.current || undefined);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);

  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }
  
  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const inviteClients = () => {

    navigate(`${Path.employeeInvite}/`);
  }

  const finalize = (
    <>




      <Box mt={2} width="100%">

      <Paper elevation={0} variant="outlined"
          sx={{
            p: 1,
            margin: 'auto',
            maxWidth: 500,
            borderRadius: "24px",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
            <Grid item>
              <ButtonBase sx={{ width: 128, height: 128 }}>
                <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.photoUrl} />

              </ButtonBase>
            </Grid>

            <Grid item container alignItems="center">
              <Grid item container alignItems="left" p={1} direction="column" >
                <Grid item>
                  <Typography align="left" gutterBottom variant="h5" component="div">
                    {data?.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="left" variant="subtitle1" gutterBottom>
                    {data?.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    <Link style={{color:"#000000"}} target="_blank" to={data ? data?.social : ""} > {data?.social}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>


        <Grid item mt={1} container justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
          <Box width="100%"  justifyItems="center" alignItems="center" justifyContent="center" alignContent="center" textAlign="center">

            <Box width="100%" justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
              <div ref={ref} />
            </Box>

            <Grid item mt={1} direction="row" justifyContent="center" alignItems="center" >
              <Button fullWidth onClick={inviteClients} variant="roundedPurple">
                Invite Clients
              </Button>
            </Grid>
            <Grid mt={1} item direction="row" justifyContent="center" alignItems="center" >
              <Share fullWidth fixed={true} shareData={shareData}>
                Share Tipz
              </Share>
            </Grid>
          </Box>
        </Grid>
        </Box>
      </>
      )


      return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
          <Grid container direction="column" alignItems="center">
            <Box >
              <Typography align="center" variant="h3" gutterBottom>My QR Code</Typography>
            </Box>

            {finalize}
          </Grid>
        </Grid>
      </Grid>
      )
}

      export default QREmployee
