import { useState, FormEvent, ChangeEvent } from 'react';
  import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
  
import { Box, Button, Typography } from '@mui/material';
  // Create a form input component
  

  const defaultFormFields = {
    displayName: '',
    email: ''
  }
  

  const PaymentForm = (params:any) => {
    const elements = useElements();
    const stripe = useStripe();
    const [isProcessingPayment, setIsProcessingPayment ] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [formFields, setFormFields] = useState(defaultFormFields);

    const { displayName, email } = formFields;

    const paymentHandler = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      //console.log("PAY!!")
      //console.log(stripe);
      //console.log(elements)

      if (!stripe || !elements) {
        return;
      }


      setIsProcessingPayment(true);

      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // redirect to route thankyou
          //return_url: 'https://tipzserver.com/#/thankyou/',
         return_url: 'https://tipzserver.com/thankyou/',
          payment_method_data: {
            billing_details: {
              name: params.name,
              email: params.email,
            }
          }
        },
      });

      //console.log("PAY2!!")
      setIsProcessingPayment(false);

      if (error) {
        //console.log("ERROR")
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        
        //console.log("SUCCESS")
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setFormFields({...formFields, [name]: value })
    }

    return (
      <div className='mt-3'>
        <form onSubmit={paymentHandler}>
          {/* Collect More User Info */}
         
          <PaymentElement />
          {errorMessage &&
            <div className='text-pink-500 p-2
              rounded-md mt-2 bold bg-pink-100'
            >
              {errorMessage}
            </div>}
          {/* isLoading will disable the button on its first click. */}

          <Box width="100%" mt={3} mb={1}>
                  <Button  fullWidth  type="submit" variant="roundedPurple">
                    <Typography variant="subtitle2" >Pay</Typography>
                  </Button>
                </Box>
        
        </form>
      </div>
    )
  };

  export default PaymentForm;
