import { Tip } from "@prisma/client";
import { api } from "./api";

export const tipsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllTips: builder.query<Tip[], void>({
      query: () => ({
        url: "/tips",
        method: "GET",
      }),
    }),
    getAllTipsBusiness: builder.query<Tip[], string>({
      query: (tipId2) => ({
        url: `/tipsBusiness/${tipId2}`,
        method: "GET",
      }),
    }),
    getAllTipsEmployee: builder.query<Tip[], string>({
      query: (tipId) => ({
        url: `/tipsEmployee/${tipId}`,
        method: "GET",
      }),
    }),
    getTip: builder.query<Tip, string>({
      query: (id) => ({
        url: `/tips/${id}`,
        method: "GET",
      }),
    }),
    editTip: builder.mutation<string, Tip>({
      query: (tip) => ({
        url: `/tips/edit/${tip.id}`,
        method: "PUT",
        body: tip,
      }),
    }),
    removeTip: builder.mutation<string, string>({
      query: (id) => ({
        url: `/tips/remove/${id}`,
        method: "DELETE",
        body: { id },
      }),
    }),
    addTip: builder.mutation<Tip, Tip>({
      query: (tip) => ({
        url: `/tips/add`,
        method: "POST",
        body: tip,
      }),
    }),
  }),
});

export const {
  useGetAllTipsQuery,
  useGetAllTipsBusinessQuery,
  useGetAllTipsEmployeeQuery,
  useGetTipQuery,
  useEditTipMutation,
  useRemoveTipMutation,
  useAddTipMutation,
} = tipsApi;
export const {
  endpoints: {
    getAllTips,
    getAllTipsBusiness,
    getAllTipsEmployee,
    getTip,
    editTip,
    removeTip,
    addTip,
  },
} = tipsApi;
