import QRCodeStyling from "qr-code-styling";
import Box from '@mui/material/Box'
import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/authContext";
import { useParams } from "react-router-dom";

type UserProps = {
  urlParam: string;
};
const QRViewComponent: React.FC<UserProps> = ({urlParam}) => {

  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();

  
  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
      dotsOptions: {
        color: "#000000",
        type: "extra-rounded"
      },
      cornersSquareOptions: {
        color: "#000000",
        type: "extra-rounded"
      },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 20
    }
  });

  const ref = useRef(null);
  useEffect(() => {
    qrCode.append(ref.current||undefined);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: urlParam
    });
  }, [urlParam]);
  

  return (
    <>

      <Box width="100%"  justifyItems="center" alignItems="center" justifyContent="center" alignContent="center">
          <div ref={ref} />
          </Box>
    </>
  )
}

export default QRViewComponent
