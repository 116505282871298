import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import QrCodeIcon from '@mui/icons-material/QrCode';

import {
  useGetBusinessQuery,
} from "../../redux/services/businesses";
import { Business } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import EmployeeList from '../../components/EmployeeList';
import { Divider, Fab } from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TipsList from '../../components/TipsListBusiness';
import TipsListBusiness from '../../components/TipsListBusiness';
import ConfigureAmplifyClientSide from '../../configureAmplify';
const DashboardBusiness: React.FunctionComponent<{}> = () => {

  //console.log("Business Dashboard")
  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();


  const authContext = useContext(AuthContext)
  const params = useParams<{ id: string }>();
  //console.log(authContext.sessionInfo?.sub)
  const { data, isLoading } = useGetBusinessQuery(authContext.sessionInfo?.sub || "");



  const [error, setError] = useState('')
  console.log(authContext)
  useEffect(() => {
    if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
      navigate(`${Path.businessEdit}/`);

    } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

      navigate(`${Path.employeeEdit}/`);
    }
  }, [authContext]);


  if (isLoading) {
    return <span>Loading</span>;
  }
  
  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const invite = () => {
    navigate(`${Path.businessInvite}/`);
  }
  const viewProfile = () => {

    navigate(`${Path.businessViewProfile}/`);
  }
  const connectStripe = () => {

    navigate(`${Path.businessStripe}/`);
  }
  const finalize = (
    <>





      <Box width="100%">
        <Typography gutterBottom variant="body1" component="div">
          Welcome {data?.businessName}
        </Typography>
        <Typography gutterBottom variant="h3" component="div">
          Dashboard
        </Typography>

      </Box>
      {data?.stripeverified === 0?
        <Button color="info" onClick={connectStripe} variant="roundedPurple">Set Up Payment</Button>
      :
<> </>
}

      <Box mt={2} width="100%" >

        <Grid container direction="row" spacing={2} >
          <Grid item width="50%">
            <Button startIcon={<PersonAddAlt1OutlinedIcon sx={{ color: "#6750A4" }} />} color="success" fullWidth type="submit" onClick={invite} variant="squarePurple">
              <Typography variant="body1" sx={{ color: "#6750A4" }}>Invite employees</Typography>
            </Button>
          </Grid>
          <Grid item width="50%">
            <Button startIcon={<AccountCircleOutlinedIcon sx={{ color: "#6750A4" }} />} color="success" fullWidth type="submit" onClick={viewProfile} variant="squarePurple">
              <Typography variant="body1" sx={{ color: "#6750A4" }}>View Profile</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>


      <Box mt={2} width="100%" p={0}>
        <TipsListBusiness stripe={data?.stripeverified} />

      </Box>

    </>
  )


  function showQR() {
    navigate(`${Path.businessQR}/`);
  }

  return (

    <Grid container direction="row" justifyContent="center" width="100%" alignItems="center">

     
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%', width: '100%' }} container direction="row" alignItems="center" item>
        <Grid width="100%" container direction="column" alignItems="center">

          {finalize}
        </Grid>
        <Box mt={2} width="100%" p={0}>
          <Button fullWidth onClick={showQR} variant="squareBlack">
          <QrCodeIcon />
          

<Typography variant="subtitle2" ml={1}>Share QR Code</Typography>
          </Button>
          </Box>
        
      </Grid>

    </Grid>
  )
}

export default DashboardBusiness
