import { Grid, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { createSearchParams, useLocation,  useNavigate,  useSearchParams } from 'react-router-dom';
import PaymentStatus from '../../utils/stripe/payment-status';

const nodeEnv: string = (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const stripePromise = loadStripe(nodeEnv);


const Thankyou = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  useEffect(() => {
    const newParams: {[key: string]: string} = {};
    
 // setSearchParams(newParams);
//  navigate({
//    search: createSearchParams(newParams).toString(),
//}, {replace: true});
  })
  //console.log("Thankyou");
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }}  container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center" >
          <Elements stripe={stripePromise}>
            <Grid container direction="column" alignItems="top" width="120px" textAlign="center">
              <svg className="inline-flex mr-2 mb-1 w-5" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
              </svg>
            </Grid>

<PaymentStatus />
            
          </Elements>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default Thankyou;
