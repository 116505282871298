import React, { useState, useContext, useEffect } from 'react'

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from 'react-number-format'
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";

import {
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { Tip } from '@prisma/client';
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { Controller, Control, useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { ButtonBase, Modal } from '@mui/material';
import { paymentIntent } from '../../utils/stripe/payment-intent-utils';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../../components/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import { StaticTokenProvider } from 'aws-sdk';
const nodeEnv: string = (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const stripePromise = loadStripe(nodeEnv);

export type StripeTypes = {
  clientSecret: string;
  tip: Tip;
  appearance: {
    theme: "stripe",
    variables: {
      colorPrimary: string
    }
  }
};
const TipCheckout: React.FunctionComponent<{}> = () => {

  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  const form = useForm<Tip>({

  })
  const { register, handleSubmit, control, watch, formState: { errors } } = form;
  const authContext = useContext(AuthContext)
  const params2 = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(params2.id || "");
  const [tip, setTip] = useState(0);

  const params = useLocation();

  const secret: any = params.state;
  //console.log(secret.tip)
  useEffect(() => {
    if (!stripePromise || !secret) {
      console.log("No Secret")
      navigate('/')

    }

    console.log(secret.tip)
    localStorage.setItem('tip', JSON.stringify(secret.tip));
    setTip(secret.totalAmount);
  }, [secret, navigate])



  const options: StripeTypes = {
    // pass the client secret
    clientSecret: secret.client_secret,
    tip: secret.tip,
    
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#008b8b'
      }
    },
  };

  const [error, setError] = useState('')

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const amountText = watch("amount");

  if (isLoading) {
    return <span>Loading</span>;
  }


  const onEdit = () => {

    //console.log("edit");
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="center" item>
        <Grid container direction="column" alignItems="center">
          <Box mt={3} mb={3}>
            <Grid container direction="column" alignItems="center">
              <Typography align="center" variant="h3" gutterBottom>You're tipping ${tip.toFixed(2)} </Typography>
            </Grid>
            <Grid container direction="column" alignItems="center">
              <Typography align="center" variant="h4" gutterBottom>Enter payment details:</Typography>
            </Grid>
          </Box>
          {secret &&
            <Elements stripe={stripePromise} options={options}>
              <PaymentForm name={secret.tip.name} email={secret.tip.email} />
            </Elements>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}


export default TipCheckout
