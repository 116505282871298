import React, { useState, useContext, useEffect } from 'react'

import { Link, useNavigate, useParams } from "react-router-dom";
import { FormInputText } from '../../components/FormInputText';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { Path } from "../../paths";
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../redux/services/employees";
import { Employee } from "@prisma/client"
import { isErrorWithMessage } from "../../utils/isErrorWithMessage";

import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'

import { AuthContext } from '../../context/authContext'
import { useForm } from "react-hook-form";
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Avatar, ButtonBase } from '@mui/material';

const FinalizeEmployee: React.FunctionComponent<{}> = () => {

  //console.log("FINAL")
  const navigate = useNavigate()
  //const { register, handleSubmit, reset, control, setValue } = useForm();

  const authContext = useContext(AuthContext)

  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetEmployeeQuery(authContext.sessionInfo?.sub || "");


  const form = useForm<Employee>({

  })
  //console.log("Complete = " + authContext.profileComplete)

  //console.log("Complete = " + authContext.sessionInfo?.profileComplete)
  if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "business") {
    navigate(`${Path.businessEdit}/`);

  } else if ((!authContext.profileComplete) && authContext.sessionInfo?.usertype === "employee") {

    navigate(`${Path.employeeEdit}/`);
  }



  const [error, setError] = useState('')




  if (isLoading) {
    return <span>Loading</span>;
  }

  const onSubmit = () => {
    //console.log(data);
  }

  const onEdit = () => {

    //console.log("edit");
  }
  const saveClicked = async () => {
    try {
      // await authContext.signUpWithEmail(email, email, password)
      //  setCreated(true)
    } catch (err) {
      if (err instanceof Error) {
        //  setError(err.message)
      }
    }
  }

  const editEmployee = () => {

    navigate(`${Path.employeeEdit}/?setup=true`);
  }
  const confirmEmployee = () => {
    if (data?.stripeverified)
      navigate(`${Path.employeeDashboard}/`);
    else
      navigate(`${Path.employeeStripe}/`);
  }

  const finalize = (
    <>


      <Grid width="100%" item>
        {/* Buttons */}
        <Box width="100%" pl={4} pb={2} justifyContent="right" mt={0} mb={0} >
          <Grid container alignItems="center" mt={0} mb={0}>

            <Grid sx={{ marginLeft: 'auto' }} mt={0} mb={0}>
              <Button color="primary" type="submit" onClick={editEmployee} variant="roundedBlack">
                <Typography variant="subtitle2">Edit</Typography>
              </Button>
            </Grid>

          </Grid>
        </Box>
        <Paper elevation={0} variant="outlined"
          sx={{
            p: 1,
            width: "100%",
            borderRadius: "24px",
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container direction="row" sx={{ width: "100%", display: "flex" }} wrap="nowrap" >
            <Grid item>
              <ButtonBase sx={{ width: 110, height: 110 }}>
                <Avatar sx={{ background: 'rgba(0,0,0,0.1)', height: "110px", width: "110px" }} src={data?.photoUrl} />

              </ButtonBase>
            </Grid>

            <Grid item container alignItems="center" width="70%" >
              <Grid item container width="100%" alignItems="left" p={1} direction="column" >
                <Grid item width="100%">
                  <Typography noWrap align="left" gutterBottom variant="h5" component="div">
                    {data?.name}
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <Typography noWrap align="left" variant="subtitle1" gutterBottom>
                    {data?.title}
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    <Link style={{ color: "#000000" }}  target="_blank" to={data ? data?.social : ""} > {data?.social}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {(data?.bio !== "" || data.social !== "") ?
          <Box mt={2} width="100%">
            <Paper elevation={0} variant="outlined"
              sx={{
                p: 2,
                margin: 'auto',
                borderRadius: "24px",
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid item xs={12} sm container>
              <Grid item xs p={2} direction="column" justifyItems="left" alignItems="left" >
                
                  <Typography align="left" variant="subtitle1" gutterBottom>

                    {data?.bio}
                  </Typography>
                
                  {data?.social ?
                    <Typography variant="subtitle1" color="text.secondary">
                      Follow {data?.name} at: <Link style={{ color: "#000000" }} target="_blank" to={data ? data.social.includes("http") ? data.social : "https://" + data.social  : ""} >{data?.social}</Link>
                    </Typography>
                    : <></>
                  }
                  
                </Grid>
              </Grid>
            </Paper>



          </Box>
          :
          <></>
        }

      </Grid>
      <Grid width="100%" item>
        <Box mt={2} width="100%" justifyContent="center">
          <Grid container direction="row" justifyContent="center">
            <Box width="100%" justifyContent="center">
              <Button fullWidth color="info" type="submit" onClick={confirmEmployee} variant="roundedPurple">
                <Typography variant="subtitle2">Confirm</Typography>
              </Button>
            </Box>

          </Grid>
        </Box>
      </Grid>
    </>
  )


  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" justifyContent="center" alignItems="center" item>
        <Grid container direction="column" alignContent="space-between" justifyContent="space-between" justifyItems="space-between" alignItems="space-between">
          <Grid item width="100%">
            {/* Title */}
            <Box >
              <Grid container direction="column" alignItems="center">
                <Typography align="center" variant="h1" >Finalize your</Typography>
                <Typography align="center" variant="h1" >Profile</Typography>
              </Grid>
            </Box>
          </Grid>
          {finalize}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinalizeEmployee
