import { useState, useEffect, useContext } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { CartContext } from '../../context/CartContext';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { Path } from '../../paths';

import { isErrorWithMessage } from "../../utils/isErrorWithMessage";
import { AuthContext } from '../../context/authContext'
import {

  useAddTipMutation
} from "../../redux/services/tips";

import { Tip } from '@prisma/client';
import { sendEmailBusinessTip, sendEmailEmployeeTip, sendEmailTip } from '../../libs/email';

const PaymentStatus: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const { clearCart } = useContext(CartContext);
  const [message, setMessage] = useState<any | null>(null);
  const [message2, setMessage2] = useState<any | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const uid = function () {
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36)
  }
  const [error, setError] = useState('')
  const authContext = useContext(AuthContext)

  const [newtip, setTip] = useState<Tip>()
  const params = useLocation();
  const secret: any = params.state;
  const [addTip] = useAddTipMutation();

  const addNewTip = async (tip: Tip) => {
    try {

      //console.log("data1 = " + tip)
      if (!tip)
        return
      tip.id = uid()
      tip.date = new Date()

      let tipString = tip.amount.toString()
      let number = Number(tipString.replace(/[^0-9\.-]+/g, ""));
      tip.amount = number


      //console.log(tip.id);
      //console.log("data = " + tip.amount)
      //console.log("Name " + tip.name)

      if (!tip.tipId){
        sendEmailBusinessTip(tip.name, tip.recipientName, tip.recipientEmail, (Math.round(tip.amount * 100) / 100).toFixed(2), tip.id, tip.date.toString(), tip.message);
      
      console.log("Name = "+tip.name+" Business="+tip.recipientName);
      }
        else
        sendEmailEmployeeTip(tip.name, tip.recipientEmail, (Math.round(tip.amount * 100) / 100).toFixed(2), tip.id, tip.date.toString(), tip.message);

      sendEmailTip(tip.recipientName, tip.email, (Math.round(tip.amount * 100) / 100).toFixed(2), tip.id, tip.date.toString(), tip.message);

      await addTip(tip).unwrap();

      //navigate(`/business/edit`);
    } catch (err) {
      const mayBeError = isErrorWithMessage(err);
      if (mayBeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }
   
    const data = localStorage.getItem('tip')

    console.log(data);
    localStorage.removeItem('tip')
    
    let tip: any = null;
    //console.log("Dat = " + data)
    if (data) {
      tip = JSON.parse(data ? data : "");
    }
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret: any = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
console.log(clientSecret)
   
    searchParams.delete('payment_intent_client_secret'); 

    setSearchParams(searchParams);  

    const queryParams = new URLSearchParams(window.location.search)
    const newParams: {[key: string]: string} = {};
               
    searchParams.forEach((value: string, key: string) => {
      newParams[key] = value;
  });

  setSearchParams(newParams);
  navigate({
      search: createSearchParams(newParams).toString(),
  }, {replace: true});

    if (queryParams.has('payment_intent_client_secret')) {
      setError('There was a problem.')
      
      queryParams.delete('payment_intent_client_secret')
    }

    if(clientSecret===null){
      
  navigate(`${Path.home}/`);
    }else{

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            // clearCart();
            setMessage(`Payment Complete`);
            setMessage2(`Thank you for showing your gratitude 
              with Tipz`);

            if (tip)
              addNewTip(tip)


            break;

          case 'processing':
            setMessage("Payment Processing");
            //if (tip)
             // addNewTip(tip)
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            navigate(-1);
            setMessage('Payment failed');
            break;

          default:
            navigate(-1);
            setMessage('Something went wrong.');
            break;
        }
      });
    }
  }, [stripe, clearCart]);



  const inviteClients = () => {

    navigate(`${Path.standardInvite}/`);
  }


  return (
    <Grid direction="column" textAlign="center" justifyContent="center">
      <Typography align="center" gutterBottom variant="h3" >{message}</Typography>
      <Typography align="center" gutterBottom variant="subtitle1" >{message2}</Typography>
      <Box height="50px" justifyContent="center" justifyItems="center" textAlign="center" />
      {message === "Payment Complete" ?
        <Typography align="center" gutterBottom variant="subtitle1" >Tell your friends about us</Typography>
        :
        <Typography align="center" gutterBottom variant="subtitle1" ></Typography>
      }
      <Typography align="center" variant="subtitle1" > </Typography>
      {message === "Payment Complete" ?
        <Typography align="center" gutterBottom variant="subtitle1" >We're a tiny team that needs all the support we can get!</Typography>
        :
        <Typography align="center" variant="subtitle1" ></Typography>
      }

      <Box width="100%" p={2} >
        {message === "Payment Complete" ?
          <Button onClick={inviteClients} type="submit" variant="roundedPurple">Invite Friends</Button>
          :
          <Typography align="center" variant="subtitle1" gutterBottom ></Typography>
        }
      </Box>


      <Typography align="center" variant="subtitle1" gutterBottom > </Typography>
      {message === "Payment Complete" ?
        <Box mt={1} mb={1} width="100%" textAlign="center"><img height={'auto'} width={'100%'} src="https://dpcy146y2wbkr.cloudfront.net/people.png" /></Box>
        :
        <Typography align="center" variant="subtitle1" ></Typography>
      }





    </Grid>
  )
}

export default PaymentStatus;
