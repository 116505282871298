import React from 'react'

import { useNavigate, Link } from 'react-router-dom'

import { makeStyles } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'



const Landing2: React.FunctionComponent = () => {

  const navigate = useNavigate()

  const signIn = () => {
    navigate('/signin')
  }
  const searchBusiness = () => {
    navigate('/searchBusinessEmployee')
  }
  const signUpBusiness = () => {
    navigate('/signupbusiness')
  }

  return (
    <Grid  container direction="row" justifyContent="center" alignItems="center">
       <Grid xs={11} sm={6} lg={4} style={{ height: '100%' }} container direction="row" alignItems="top" item>
     

        <Box  mb={2} >
          <Grid container direction="row" alignItems="center">
            <Box mb={1} width="100%" textAlign="center" justifyContent="center">
              <img height={'235px'} width={'246px'} src="https://dpcy146y2wbkr.cloudfront.net/img1.png" /></Box>
            <Box mt={3}  width="100%" textAlign="center" justifyContent="center">
              <Typography gutterBottom align="center"  sx={{ fontSize: "44px" }} variant="h6">
                Tipz
              </Typography>
            </Box>

            <Box mt={1} width="100%" textAlign="center" justifyContent="center">
            <Typography gutterBottom align="center" variant="h4">
              Did you make someone’s day?
              </Typography>
              <Typography gutterBottom align="center" variant="h4">
             
Tipz lets them find you or your business and say thank you through a seamless tipping experience. 
              </Typography>
            </Box>
            
        <Box mt={1}  width="100%">
          <Button onClick={searchBusiness} fullWidth variant="roundedBlack" color="primary" >
          Employee Signup
          </Button>
        </Box>
        <Box mt={1} mb={1} width="100%">
          <Button onClick={signUpBusiness} fullWidth variant="roundedGrey" color="success"  >
            Business Signup
          </Button>
        </Box>
        
        </Grid>
        <Box mt={1} mb={1} width="100%" textAlign="center" justifyContent="center">
              <Typography gutterBottom align="center" variant="subtitle1">
              Already have an account or want to search for a business to tip?
              </Typography>
              <Typography gutterBottom align="center" sx={{textDecoration:"none"}} variant="subtitle2">
              <Link to="/">Discover Tipz</Link>
              </Typography>
            </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Landing2
