import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useRoutes, useSearchParams, useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import LogoutIcon from '@mui/icons-material/Logout';
import TextField from '@mui/material/TextField'
import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks'
import { Path } from "../../paths";

import { AuthContext, AuthStatus } from '../../context/authContext'
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { AppBar, BottomNavigation, BottomNavigationAction, List, ListItem, Toolbar } from '@mui/material'
import QrCodeIcon from '@mui/icons-material/QrCode';
import PaidIcon from '@mui/icons-material/Paid';
import EmailIcon from '@mui/icons-material/Email';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Hub } from 'aws-amplify/utils'
const Header: React.FunctionComponent<{}> = () => {
  ////console.log("HEADER")
  const navigate = useNavigate()

  //const auth = useContext(AuthContext)
  const [error, setError] = useState('')

  const authContext = useContext(AuthContext)

  const signOut = async()=>{
    await authContext.signOut();
  };

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    
  
    
    if (searchParams.get("new") === "true") {
     signOut();
    };
    
    
  }, [])




  function editClicked() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessViewProfile}/`);
    else
      navigate(`${Path.employeeViewProfile}/`);

  }
  function paymentClicked() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessStripe}/`);
    else
      navigate(`${Path.employeeStripe}/`);

  }
  function inviteClicked() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessInvite}/`);
    else
      navigate(`${Path.employeeInvite}/`);

  }
  function goHome() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessDashboard}`)
    else if (authContext.sessionInfo?.usertype === "employee")
      navigate(`${Path.employeeDashboard}`)
    else
      navigate(`${Path.home}`)
  }

  function goLanding() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessLanding}`)
    else if (authContext.sessionInfo?.usertype === "employee")
      navigate(`${Path.employeeLanding}`)
    else
      navigate(`${Path.home}`)
  }
  function goBack(){
    navigate(-1);
  }
  function qrClicked() {
    if (authContext.sessionInfo?.usertype === "business")
      navigate(`${Path.businessQR}/`);
    else
      navigate(`${Path.employeeQR}/`);
  }

  return (

    <Box sx={{ display: 'flex' }} justifyContent='center' alignItems="center">

      <Grid style={{ height: '60px' }} container columns={12} direction="row" justifyItems="space-between" alignItems="center">

        {authContext.authStatus === AuthStatus.SignedIn ?
          <Typography align="center" onClick={goLanding} fontSize={"44"} variant="h6"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}>Tipz</Typography>
          :
          window.location.href.toLowerCase().includes("business") ||
            window.location.href.toLowerCase().includes("employee") ||
            window.location.href.toLowerCase().includes("thankyou") ||
            window.location.href.toLowerCase().includes("verify") ||
            window.location.href.toLowerCase().includes("invite") ||
            window.location.href.toLowerCase().includes("tipcheckout") ||
            window.location.href.toLowerCase().includes("signin") ||
            window.location.href.toLowerCase().includes("faq") ||
            window.location.href.toLowerCase().includes("request")
            ?
            <Grid container p={2}>
              <Grid item>
                <Typography align="left" variant="h6" onClick={goHome}
                  sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }} >Tipz</Typography>
              </Grid>

              {window.location.href.toLowerCase().includes("thankyou")?
              <></>
              :
              <Grid item xs justifyContent="center" alignContent="center"  justifyItems="center" alignItems="center">
                <Grid container direction="row-reverse" >
                  <Grid item justifyContent="center" alignContent="center"  justifyItems="center" alignItems="center"> <Typography align="right"  variant="subtitle1"
                    sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }} onClick={goBack}>Back</Typography>
                  </Grid>
                </Grid>
              </Grid>
}
            </Grid>

            : ""}


      </Grid >

    </Box >
  )
}

export default Header
